@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(../lib/assets/fonts/Rubik-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(../lib/assets/fonts/OpenSans.ttf) format('truetype');
}
